<template>
  <div>
      <CCard>
        <CCardHeader color="info" text-color="white">
          Contract
        </CCardHeader>
        <CCardBody>
            <CRow class="ms-row">
      <CCol sm="1">
        <CInput
            :value="this.contract? this.contract.nid: null"
            description="NID"
            :readonly=true
        />
      </CCol>
      <CCol sm="5">
        <multiselect
            data-sel="slct-clnt"
            :disabled="!allowEdit"
            v-model="contract.client"
            @input="updateItem"
            :options="this.clients ? this.clients.data? this.clients.data: []: []"
            :show-labels="true"
            label="name"
            track-by="name"
            :searchable="true"
            :close-on-select="true"
            :allow-empty="false"
            select-label="Select"
            deselect-label="Required field"
            placeholder="Select client"
            v-on:search-change="updateClientQ"
        />
        <CRow>
          <small class="form-text text-muted w-100">Client</small>
        </CRow>
      </CCol>
      <CCol sm="2">
        <multiselect
            data-sel="slct-enty"
            :disabled="!allowEdit"
            v-model="contract.entity"
            @input="updateItem"
            :options="Object.keys(this.entityOptions)"
            :custom-label="(item) => this.entityOptions[item]"
            :searchable="true"
            :close-on-select="true"
            :allow-empty="false"
            placeholder="Select Entity"
            select-label="Select"
            deselect-label="Required field"
        />
        <CRow>
          <small class="form-text text-muted w-100">Entity</small>
        </CRow>
      </CCol>
      <CCol sm="2">
        <multiselect
            data-sel="slct-dmn"
            :disabled="!allowEdit"
            v-model="contract.domain"
            @input="updateItem"
            :options="Object.keys(this.domainOptions)"
            :custom-label="(item) => this.domainOptions[item]"
            :searchable="true"
            :close-on-select="true"
            :allow-empty="false"
            placeholder="Select domain"
            select-label="Select"
            deselect-label="Required field"
        />
        <CRow>
          <small class="form-text text-muted w-100">Domain</small>
        </CRow>
      </CCol>
      <CCol sm="2">
        <CInput
            data-sel="date-sign"
            type="text"
            v-model="displayedDates.signingDate"
            @change="updateDate('signingDate')"
            :is-valid="isDateFormat(displayedDates.signingDate)"
            description="Signing date"
            :readonly="!allowEdit"
        />
      </CCol>
    </CRow>
        </CCardBody>
      </CCard>

    <FormDetailSection
        section="Overview"
        v-bind:showSection="showOverview"
        v-on:toggle="showOverview = !showOverview"
    />
    <CCollapse
        :show.sync="showOverview"
        :duration="1"
    >
        <CCard>
        <CCardHeader color="info" text-color="white">Contract Overview</CCardHeader>
        <CCardBody>
          <ContractOverview v-bind:contract-in="contract"/>
        </CCardBody>
        </CCard>

    </CCollapse>
    <FormDetailSection
        section="Contract periods"
        v-bind:showSection="showContractPeriods"
        v-on:toggle="showContractPeriods = !showContractPeriods"
    />
    <CCollapse
        :show.sync="showContractPeriods"
        :duration="1"
    >
        <CCard>
        <CCardHeader color="info" text-color="white">Contract Periods</CCardHeader>
        <CCardBody>
      <CRow>
        <CCol sm="3">
          <CInput
            data-sel="date-cntr-start"
            type="text"
            v-model="displayedDates.startContract"
            @change="updateDate('startContract')"
            :is-valid="isDateFormat(displayedDates.startContract)"
            description="Start contract"
            :readonly="!allowEdit"
          />
        </CCol>
        <CCol sm="3">
          <CInput
              data-sel="date-cntr-end"
              type="text"
              v-model="displayedDates.endContract"
              @change="updateDate('endContract')"
              :is-valid="isNullOrDateFormat(displayedDates.endContract)"
              description="End contract"
              :readonly="!allowEdit"
          >
            <template #prepend-content>
              <CButtonClose
                  v-if="allowEdit"
                  color="primary"
                  @click="contract.endContract = null"
                  v-on:click="updateItem"
              />
            </template>
          </CInput>
        </CCol>
        <CCol sm="3">
          <CInput
              data-sel="date-term-start"
              type="text"
              v-model="displayedDates.startTerm"
              @change="updateDate('startTerm')"
              :is-valid="isDateFormat(displayedDates.startTerm)"
              description="Start term"
              :readonly="!allowEdit"
          />
        </CCol>
        <CCol sm="3">
          <CInput
              data-sel="date-term-end"
              type="text"
              v-model="displayedDates.endTerm"
              @change="updateDate('endTerm')"
              :is-valid="isNullOrDateFormat(displayedDates.endTerm)"
              description="End term"
              :readonly="!allowEdit"
          >
            <template #prepend-content>
              <CButtonClose
                  v-if="allowEdit"
                  color="primary"
                  @click="contract.endTerm = null"
                  v-on:click="updateItem"
              />
            </template>
          </CInput>
        </CCol>
      </CRow>
        </CCardBody>
        </CCard>
    </CCollapse>
    <FormDetailSection
        section="Positions"
        v-bind:showSection="showPositions"
        v-on:toggle="showPositions = !showPositions"
    />
    <CCollapse
        :show.sync="showPositions"
        :duration="1"
    >
        <CCard>
        <CCardHeader color="info" text-color="white">Contract Positions</CCardHeader>
        <CCardBody>
    <CRow class="justify-content-center">
      <CContainer>
        <div v-for="item in contract.positions" :key="item.nid">
          <ContractPositionForm
              class="position-box"
              v-bind:positionIn="item"
              v-on:del-position="removePositionLocal"
              v-on:update-position="updateItem"
              v-on:error="(error) => $emit('error', error)"
          />
        </div>
      </CContainer>
      <CButton
          data-sel="btn-add-posi"
          v-if="allowEdit"
          @click="addPositionLocal"
      >
        <CIcon name="cil-plus"/>
      </CButton>
    </CRow>
        </CCardBody>
        </CCard>
    </CCollapse>
    <FormDetailSection
        data-sel="btn-sctn-cncl"
        section="Cancellation"
        v-bind:showSection="showCancellation"
        v-on:toggle="showCancellation = !showCancellation"
    />
    <CCollapse
        :show.sync="showCancellation"
        :duration="1"
    ><CCard>
        <CCardHeader color="info" text-color="white">Cancellation</CCardHeader>
        <CCardBody>
      <CRow class="ms-row">
        <CCol sm="4">
          <multiselect
              data-sel="slct-rnwl"
              :disabled="!allowEdit"
              v-model="contract.automaticContractRenewal"
              @input="updateItem"
              :options="Object.keys(this.automaticContractRenewalOptions)"
              :custom-label="(item) => this.automaticContractRenewalOptions[item]"
              :searchable="true"
              :close-on-select="true"
              :allow-empty="true"
              placeholder="Select automatic contract renewal"
              select-label="Select"
              deselect-label="Remove"
          />
          <CRow>
            <small class="form-text text-muted w-100">Automatic contract renewal</small>
          </CRow>
        </CCol>
        <CCol sm="5">
          <multiselect
              data-sel="slct-cnclprd"
              :disabled="!allowEdit"
              v-model="contract.cancellationPeriod"
              @input="updateItem"
              :options="Object.keys(this.cancellationPeriodOptions)"
              :custom-label="(item) => this.cancellationPeriodOptions[item]"
              :searchable="true"
              :close-on-select="true"
              :allow-empty="true"
              deselect-label="Remove"
              placeholder="Select cancellation period"
          />
          <CRow>
            <small class="form-text text-muted w-100">Cancellation period</small>
          </CRow>
        </CCol>
        <CCol sm="3">
          <CInput
              data-sel="date-cnclrght"
              type="text"
              v-model="displayedDates.specialCancellationRight"
              @change="updateDate('specialCancellationRight')"
              :is-valid="isNullOrDateFormat(displayedDates.specialCancellationRight)"
              description="Special cancellation right"
              :readonly="!allowEdit"
          >
            <template #prepend-content>
              <CButtonClose
                  v-if="allowEdit"
                  color="primary"
                  @click="contract.specialCancellationRight = null"
                  v-on:click="updateItem"
              />
            </template>
          </CInput>
        </CCol>
      </CRow>
      <CRow>
        <CCol sm="4">
          <CInput
              data-sel="date-cncl"
              type="text"
              v-model="displayedDates.cancellationReceivedDate"
              @change="updateDate('cancellationReceivedDate')"
              :is-valid="isNullOrDateFormat(displayedDates.cancellationReceivedDate)"
              description="Cancellation received date"
              :readonly="!allowEdit"
          >
            <template #prepend-content>
              <CButtonClose
                  v-if="allowEdit"
                  color="primary"
                  @click="contract.cancellationReceivedDate = null"
                  v-on:click="updateItem"
              />
            </template>
          </CInput>
        </CCol>
      </CRow>
        </CCardBody>
    </CCard>
    </CCollapse>
    <FormDetailSection
        data-sel="btn-sctn-paym"
        section="Payments"
        v-bind:showSection="showPayments"
        v-on:toggle="showPayments = !showPayments"
    />
    <CCollapse
        :show.sync="showPayments"
        :duration="1"
    >
        <CCard>
        <CCardHeader color="info" text-color="white">Payment & Invoicing Options</CCardHeader>
        <CCardBody>
      <CRow>
        <CCol sm="4">
          <multiselect
              data-sel="slct-invdate"
              :disabled="!allowEdit"
              v-model="contract.invoiceDate"
              @input="updateItem"
              :options="Object.keys(this.invoiceDateOptions)"
              :custom-label="(item) => this.invoiceDateOptions[item]"
              :searchable="true"
              :close-on-select="true"
              :allow-empty="false"
              placeholder="Select invoice date"
              select-label="Select"
              deselect-label="Required field"
          />
          <CRow>
            <small class="form-text text-muted w-100">Invoice date</small>
          </CRow>
        </CCol>
        <CCol sm="4">
          <multiselect
              data-sel="slct-paytar"
              :disabled="!allowEdit"
              v-model="contract.paymentTarget"
              @input="updateItem"
              :options="Object.keys(this.paymentTargetOptions)"
              :custom-label="(item) => this.paymentTargetOptions[item]"
              :searchable="true"
              :close-on-select="true"
              :allow-empty="false"
              placeholder="Select payment target"
              select-label="Select"
              deselect-label="Required field"
          />
          <CRow>
            <small class="form-text text-muted w-100">Payment target</small>
          </CRow>
        </CCol>
        <CCol sm="1">
          <CRow v-if="allowEdit"
              class="justify-content-center switch"
          >
            <CSwitch
                data-sel="btn-sepa"
                :checked.sync="contract.sepa"
                v-on:update:checked	="updateItem"
                color="info"
                size="lg"
                variant="opposite"
                labelOn="Yes"
                labelOff="No"
            />
          </CRow>
          <CRow v-if="allowEdit">
            <small class="form-text text-muted w-100">SEPA</small>
          </CRow>
          <CInput
              v-if="!allowEdit"
              :is-valid="true"
              :value="contract.sepa ? 'Yes': 'No'"
              :readonly="true"
              description="SEPA"
          />
        </CCol>
      </CRow>
        </CCardBody>
        </CCard>
    </CCollapse>
    <FormDetailSection
        data-sel="btn-sctn-misc"
        section="Miscellaneous"
        v-bind:showSection="showMiscellaneous"
        v-on:toggle="showMiscellaneous = !showMiscellaneous"
    />
    <CCollapse
        :show.sync="showMiscellaneous"
        :duration="1"
    >
        <CCard>
        <CCardHeader color="info" text-color="white">Misc. Settings</CCardHeader>
        <CCardBody>
      <CRow>
        <CCol sm="12">
          <CInput
              data-sel="ipt-link"
              v-model="contract.link"
              v-on:change="updateItem"
              placeholder="Enter contract PDF link"
              description="Link to contract PDF"
              :readonly="!allowEdit"
              @input="contract.link = $event !== '' ? $event : null"
          />
        </CCol>
      </CRow>
        </CCardBody>
        </CCard>
    </CCollapse>
  </div>
</template>

<script>
import gql from "graphql-tag";
import moment from 'moment';
import ContractPositionForm from "@/apps/contracts/components/ContractPositionForm";
import ContractOverview from "@/apps/contracts/components/ContractOverview";
import FormDetailSection from "@/components/common/FormDetailSection";
const clonedeep = require('lodash.clonedeep')

const queryClients = gql`
query clients($q: String){
  clients(q: $q, order: "name") {
    data {
      nid
      name
    }
  }
}
`
export default {
  name: "ContractForm",
  props: ["contractIn"],
  components: {
    ContractOverview,
    FormDetailSection,
    ContractPositionForm
  },
  data(){
    return {
      displayedDates: {
        startContract: null,
        endContract: null,
        startTerm: null,
        endTerm: null,
        signingDate: null,
        specialCancellationRight: null,
        cancellationReceivedDate: null
      },
      clients: [],
      clientQ: null,
      entityOptions: {
        MS: 'MS Media',
        PW: 'PW Internet',
        IM: 'Immowelt',
      },
      domainOptions: {
        fertighaus_de: "fertighaus.de",
        fertighaus_at: "fertighaus.at",
        fachwerkhaus_de: "fachwerkhaus.de",
        baumarkt_de: "baumarkt.de",
        baddesign_de: "baddesign.de",
        traumbad_de: "traumbad.de",
        fliesenhandel_de: "fliesenhandel.de",
        luxus_fertighaus_de: "luxus-fertighaus.de",
        baumarkt_shopping_de: "baumarkt-shopping.de",
        kachelofen_online_de: "kachelofen-online.de",
        das_baulexikon_de: "das-baulexikon.de",
        handwerkersuche_de: "handwerkersuche.de",
        massivhaus_de: "massivhaus.de",
        einfamilienhaus_de: "einfamilienhaus.de",
        bungalow_de: "bungalow.de",
        immowelt_de: "immowelt.de"
      },
      cancellationPeriodOptions: {
        no: "No",
        months_1: "1 Month",
        months_1_quartlery: "1 Month until end of quarter",
        months_3_quartlery: "3 Months until end of quarter",
        months_2: "2 Months",
        quarterly: "Quarterly",
        months_1_eoc: "1 Month before end of contract",
        months_3_eoc: "3 Months before end of contract",
        months_6_adv3: "6 Months with 3 months advance notice",
        daily: 'Daily'
      },
      automaticContractRenewalOptions: {
        no: "No",
        yes_months_6: "Yes, every 6 months",
        yes_months_12: "Yes, every 12 months"
      },
      invoiceDateOptions: {
        month_start: '1st',
        month_mid: '15th',
        month_end: '28th'
      },
      paymentTargetOptions: {
        days_10: '10 days',
        days_14: '14 days',
        days_30: '30 days',
        days_0: '0 days',
        days_7: '7 days',
      },
      showContractPeriods: true,
      showOverview: true,
      showPositions: true,
      showCancellation: false,
      showPayments: false,
      showMiscellaneous: false
    }
  },
  methods: {
    updateDate(key) {
      const date = moment(this.displayedDates[key], 'DD.MM.YYYY');
      if (date.isValid()) {
        this.contract[key] = date.format('YYYY-MM-DD');
        this.updateItem();
      }
    },
    updateItem() {
      // logic needs to be implemented on the parent component, as the 'is valid' button is added on the parent form
      const data = clonedeep(this.contract)
      this.$emit('update-item', data);
    },
    notNull(val) {
        return val !== undefined && val !== null && val !== ''
    },
    isNullOrDateFormat(val) {
        if (val === undefined || val === null || val === '') {
            return true
        } else {
            const date = moment(val, 'DD.MM.YYYY');
            return date.isValid()
        }
    },
    isDateFormat(val) {
        const date = moment(val, 'DD.MM.YYYY');
        return date.isValid()
    },
    removeError(hide){
      if(!hide){
        this.error = null
      }
    },
    updateClientQ(event){
      this.clientQ = event
    },
    removePositionLocal(data) {
      this.contract.positions = this.contract.positions
          .filter(position => position.idx !== data.idx)
          .map((item, idx) => { return {...item, idx}})
      this.updateItem();
    },
    addPositionLocal(){
      let newPosition = {
        product: {
          nid: null,
          name: null,
        },
        startTerm: this.contract.startTerm,
        endTerm: this.contract.endTerm,
        pricePerItem: null,
        ppcRate: null,
        ppcAmount: null,
        numberOfItems: null,
        isReimbursement: false,
        isDiscount: false,
        invoiceCycle: "monthly",
        notes: null
      }
      if(typeof this.contract.positions === 'undefined'){
        this.contract.positions = [newPosition]
        this.contract.positions = this.contract.positions.map((item, idx) => { return {...item, idx}})
      }else{
        this.contract.positions.push(newPosition)
        this.contract.positions = this.contract.positions.map((item, idx) => { return {...item, idx}})
      }
      this.updateItem();
    },
  },
  apollo: {
    clients: {
      query: queryClients,
      variables(){
        return {
          q: this.clientQ
        }
      },
      error(error) {
        console.error(error);
        this.$emit("error", JSON.stringify(error));
      }
    },
  },
  computed: {
    contract(){
      return this.contractIn? this.contractIn: {}
    },
    allowEdit: {
      get: function() {
        return this.$store.state.allowEdit
      },
      set: function (){
      }
    },
  },
  watch:{
    'contract.startContract'() {
      this.displayedDates.startContract = this.contract.startContract ? moment(this.contract.startContract, 'YYYY-MM-DD').format('DD.MM.YYYY') : '';
    },
    'contract.endContract'() {
      this.displayedDates.endContract = this.contract.endContract ? moment(this.contract.endContract, 'YYYY-MM-DD').format('DD.MM.YYYY') : '';
    },
    'contract.startTerm'() {
      this.displayedDates.startTerm = this.contract.startTerm ? moment(this.contract.startTerm, 'YYYY-MM-DD').format('DD.MM.YYYY') : '';
    },
    'contract.endTerm'() {
      this.displayedDates.endTerm = this.contract.endTerm ? moment(this.contract.endTerm, 'YYYY-MM-DD').format('DD.MM.YYYY') : '';
    },
    'contract.signingDate'() {
      this.displayedDates.signingDate = this.contract.signingDate ? moment(this.contract.signingDate, 'YYYY-MM-DD').format('DD.MM.YYYY') : '';
    },
    'contract.specialCancellationRight'() {
      this.displayedDates.specialCancellationRight = this.contract.specialCancellationRight ? moment(this.contract.specialCancellationRight, 'YYYY-MM-DD').format('DD.MM.YYYY') : '';
    },
    'contract.cancellationReceivedDate'() {
      this.displayedDates.cancellationReceivedDate = this.contract.cancellationReceivedDate ? moment(this.contract.cancellationReceivedDate, 'YYYY-MM-DD').format('DD.MM.YYYY') : '';
    },
    contractIn(){
      this.updateItem();
    },
    contract(){
      this.updateItem();
    }
  },
  created() {
    for (let key in this.displayedDates) {
      this.displayedDates[key] = this.contract[key] ? moment(this.contract[key], 'YYYY-MM-DD').format('DD.MM.YYYY') : '';
    }
  }
}
</script>

<style scoped>
  .multiselect {
    border-width: 2pt;
    list-style-type: none;
    margin-bottom: 1pt;
  }
  .ms-row {
    margin-bottom: .75em;
 }
  .switch {
    margin-bottom: .2em;
    margin-top: .15em;
  }
  .positions-container{
    background-color: #fff;
    border: 1px solid;
    border-radius: 0.25rem;
    border-color: #d8dbe0;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 1px;
    padding-right: 1px;
    margin: 0px;
    margin-bottom: 1rem;
  }
  .position-box{
    border: 1px solid #d8dbe0;
    color: #768192;
    background-color: #ebedef;
    border-radius: 0.25rem;
    padding-top: 10px;
    margin: 1px 1px 5px 1px;
  }
</style>