<template>
  <CRow class="align-items-center">
    <CCol sm="1">
      <CRow class="justify-content-center">
        <CButtonClose
            v-if="allowEdit"
            buttonClasses="text-danger close"
            @click="deleteItem"
        />
      </CRow>
    </CCol>
    <CCol sm="11">
      <CRow class="row-pos">
          <CCol sm="4">
            <CInput
                data-sel="ipt-itmnum"
                v-model="position.numberOfItems"
                v-on:change="updateItem"
                type="number"
                @wheel="$event.target.blur()"
                description="Number of items"
                :is-valid="notNull"
                :readonly="!allowEdit"
            />
          </CCol>
        <CCol sm="8">
          <multiselect
              data-sel="slct-prd"
              :disabled="!allowEdit"
              v-model="position.product"
              @input="updateItem"
              :options="this.products ? this.products.data ? this.products.data : [] : []"
              :show-labels="true"
              label="name"
              track-by="name"
              :searchable="true"
              :close-on-select="true"
              :allow-empty="false"
              select-label="Select"
              deselect-label="Required field"
              placeholder="Select product"
              v-on:search-change="updateProductQ"
          />
          <CRow>
            <small class="form-text text-muted w-100">Product</small>
          </CRow>
          </CCol>
        </CRow>
        <CRow class="row-pos">
        <CCol sm="4">
          <CInput
              data-sel="date-posi-term-start"
              type="text"
              v-model="displayedDates.startTerm"
              @change="updateDate('startTerm')"
              :is-valid="isDateFormat(displayedDates.startTerm)"
              description="Start term"
              :readonly="!allowEdit"
          />
        </CCol>
        <CCol sm="4">
          <CInput
              data-sel="date-posi-term-end"
              v-model="displayedDates.endTerm"
              @change="updateDate('endTerm')"
              :is-valid="isNullOrDateFormat(displayedDates.endTerm)"
              type="text"
              description="End Term"
              :readonly="!allowEdit"
          >
            <template #prepend-content>
              <CButtonClose
                  v-if="allowEdit"
                  color="primary"
                  @click="position.endTerm = null"
                  v-on:click="updateItem"
              />
            </template>
          </CInput>
        </CCol>
        <CCol sm="4">
          <multiselect
              data-sel="slct-invcycl"
              :disabled="!allowEdit"
              v-model="position.invoiceCycle"
              @input="updateItem"
              :options="this.invoiceCycleOptions? Object.keys(this.invoiceCycleOptions): []"
              :custom-label="(item) => this.invoiceCycleOptions ? this.invoiceCycleOptions[item]: ''"
              :searchable="true"
              :close-on-select="true"
              :allow-empty="false"
              placeholder="Select invoice cycle"
              select-label="Select"
              deselect-label="Required field"
          />
          <CRow>
            <small class="form-text text-muted w-100">Invoice cycle</small>
          </CRow>
        </CCol>
      </CRow>
      <CRow class="row-pos">
        <CCol sm="3">
          <CInput
              data-sel="ipt-ppi"
              v-model="position.pricePerItem"
              v-on:change="updateItem"
              type="number"
              @wheel="$event.target.blur()"
              description="Price per item"
              :is-valid="notNull"
              append="€"
              :readonly="!allowEdit"
          />
        </CCol>
        <CCol sm="3">
          <CInput
              data-sel="ipt-ppcrt"
              type="number"
              @wheel="$event.target.blur()"
              v-on:update:value="setPPCRate"
              v-on:change="updateItem"
              :value="ppcRate"
              min="0"
              max="100"
              step="0.1"
              append="%"
              description="PPC rate"
              :readonly="!allowEdit"
          />
        </CCol>
        <CCol sm="3">
          <CInput
              data-sel="ipt-ppcamnt"
              v-model="position.ppcAmount"
              v-on:change="updateItem"
              type="number"
              @wheel="$event.target.blur()"
              append="€"
              description="PPC amount"
              :readonly="!allowEdit"
              @input="position.ppcAmount = $event !== '' ? $event : null"
          >
          </CInput>
        </CCol>
        <CCol sm="1">
          <CRow class="justify-content-center switch">
            <CSwitch
                data-sel="btn-dcnt"
                :disabled="!allowEdit"
                :checked.sync="position.isDiscount"
                v-on:update:checked="updateItem"
                color="info"
                size="lg"
                variant="opposite"
                labelOn="Yes"
                labelOff="No"
            />
          </CRow>
          <CRow>
            <small class="form-text text-muted w-100">Discount</small>
          </CRow>
        </CCol>
        <CCol sm="2">
          <CRow class="justify-content-center switch">
            <CSwitch
                data-sel="btn-rbsmnt"
                :disabled="!allowEdit"
                :checked.sync="position.isReimbursement"
                v-on:update:checked	="updateItem"
                color="info"
                size="lg"
                variant="opposite"
                labelOn="Yes"
                labelOff="No"
            />
          </CRow>
          <CRow>
            <small class="form-text text-muted w-100">Reimbursement</small>
          </CRow>
        </CCol>
      </CRow>
      <CRow class="row-pos ms-row">
        <CCol>
          <CTextarea
              data-sel="ipt-notes"
              v-model="position.notes"
              v-on:update:value="updateItem"
              description="Notes"
              :readonly="!allowEdit"
              @input="position.notes = $event !== '' ? $event : null"
          />
        </CCol>
      </CRow>
    </CCol>
  </CRow>
</template>

<script>
import gql from "graphql-tag";
import moment from 'moment';
const clonedeep = require('lodash.clonedeep')

const queryProducts = gql`
query products($q: String){
  products(q:$q, order: "name") {
    data {
      nid
      name
    }
  }
}
`
export default {
  name: "ContractPositionForm",
  props: ["positionIn"],
  data() {
    return {
      displayedDates: {
        startTerm: null,
        endTerm: null,
      },
      products: [],
      productQ: null,
      invoiceCycleOptions: {
        monthly: "Monthly",
        quarterly: "Quarterly",
        yearly: "Yearly",
        months_3: "Every 3 months"
      }
    }
  },
  methods: {
    updateDate(key) {
      const date = moment(this.displayedDates[key], 'DD.MM.YYYY');
      if (date.isValid()) {
        this.position[key] = date.format('YYYY-MM-DD');
        this.updateItem();
      }
    },
    updateItem(){
      this.$emit('update-position', this.position)
    },
    deleteItem(){
      this.$emit('del-position', this.position)
    },
    notNull(val) {
      return val !== undefined && val !== null && val !== ''
    },
    isNullOrDateFormat(val) {
        if (val === undefined || val === null || val === '') {
            return true
        } else {
            const date = moment(val, 'DD.MM.YYYY');
            return date.isValid()
        }
    },
    isDateFormat(val) {
        const date = moment(val, 'DD.MM.YYYY');
        return date.isValid()
    },
    setPPCRate(val){
      this.position.ppcRate = val/100;
    },
    updateProductQ(event){
      this.productQ = event
    },
  },
  apollo: {
    products: {
      query: queryProducts,
      variables() {
        return {
          q: this.productQ
        }
      },
      error(error) {
        console.error(error);
        this.$emit("error", JSON.stringify(error));
      }
    }
  },
  computed: {
    position(){
      return this.positionIn? this.positionIn: {}
    },
    allowEdit: {
      get: function () {
        return this.$store.state.allowEdit
      },
      set: function () {
      }
    },
    ppcRate: function(){
      const ppcRate = this.position.ppcRate*100;
      return ppcRate
    }
  },
  watch: {
    'position.startTerm'() {
      this.displayedDates.startTerm = this.position.startTerm ? moment(this.position.startTerm, 'YYYY-MM-DD').format('DD.MM.YYYY') : '';
    },
    'position.endTerm'() {
      this.displayedDates.endTerm = this.position.endTerm ? moment(this.position.endTerm, 'YYYY-MM-DD').format('DD.MM.YYYY') : '';
    },
  },
  created() {
    for (let key in this.displayedDates) {
      this.displayedDates[key] = this.position[key] ? moment(this.position[key], 'YYYY-MM-DD').format('DD.MM.YYYY') : '';
    }
  }
}
</script>

<style scoped>
  .row-pos{
    margin-right: 0pt;
  }
  .multiselect {
    border-width: 2pt;
    list-style-type: none;
    margin-bottom: 1pt;
  }
  .ms-row {
    margin-bottom: .75em;
 }
  .switch {
    margin-bottom: .2em;
    margin-top: .15em;
  }
</style>