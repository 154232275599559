<template>
  <div>
      <CCard body-wrapper>
      <CRow>
          <CCol sm="2" class="text-left">Contract Period:</CCol><CCol sm="4" class="text-left">{{ contractStartMin }} - {{ contractEndMax }}</CCol>
          <CCol sm="2" class="text-left">Contract Cancelled:</CCol><CCol sm="4" class="text-left">{{ contract ? contract.cancellationReceivedDate ? contract.cancellationReceivedDate : "Not cancelled" : '' }}</CCol>
      </CRow>
      <CRow>
          <CCol sm="2" class="text-left">Billing Cycles:</CCol><CCol sm="4" class="text-left">{{  billingCyclesOverview }}</CCol>
          <CCol sm="2" class="text-left">Total Revenue:</CCol><CCol sm="4" class="text-left"><span class="text-success">{{ totalRevenue }} €</span></CCol>
      </CRow>
      <CRow>
          <CCol sm="2" class="text-left">SEPA Active:</CCol><CCol sm="4" class="text-left">{{  contract? contract.sepa : '' }}</CCol>
          <CCol sm="2" class="text-left">Sum of Reimbursements:</CCol><CCol sm="4" class="text-left"><span class="text-danger">{{ totalReimbursements }} €</span></CCol>
      </CRow>
          </CCard>
      <CCard>
          <CCardBody>
              <CDataTable
          :items="tableItems"
          :fields="tableFields"
      />
          </CCardBody>
      </CCard>

  </div>
</template>

<script>

export default {
  name: "ContractOverview",
  props: ['contractIn'],
  data() {
    return {
      invoiceCycleOptions: {
        monthly: "Monthly",
        quarterly: "Quarterly",
        yearly: "Yearly",
        months_3: "Every 3 months",
      },
    }
  },
  methods: {

  },
  computed: {
    contract(){
      if (this.contractIn.positions === undefined || !this.contractIn) {
        return null
      }
      return this.contractIn
    },
    contractStartMin(){
      if (!this.contract) {
        return ''
      }
      const dates = [
          this.contract.startTerm,
          this.contract.startContract,
          ...this.contract.positions.map(({startTerm}) => startTerm)
      ]
      return dates.reduce(function (a, b) { return a < b ? a : b; });
    },
    contractEndMax(){
      if (!this.contract) {
        return ''
      }
      const dates = [
        this.contract.endTerm,
        this.contract.endContract,
        ...this.contract.positions.map(({endTerm}) => endTerm)
      ];
      return dates.reduce(function (a, b) { return a > b ? a : b; });
    },
    billingCyclesOverview(){
      if (!this.contract) {
        return ''
      }
      const cycles = this.contract.positions
        .reduce((finalObj, current) => {
          const key = this.invoiceCycleOptions[current.invoiceCycle];
          return {
          ...finalObj,
          [key]: finalObj[key] ? finalObj[key] + 1 : 1
          }
        }, {})

      let returnString = ''
      for (let invoiceCycle in cycles) {
        returnString = returnString + invoiceCycle + ' (' + cycles[invoiceCycle] + 'x); ';
      }
      return returnString
    },
    totalRevenue(){
      if (!this.contract) {
        return ''
      }
      const revenue = Object.values(this.positionsDataObj)
        .reduce((accumulate, current) => accumulate + Object.values(current)
          .reduce((partialAccumulate, partialCurrent) => partialAccumulate + partialCurrent.price, 0), 0)

      return Math.round(revenue)
    },
    totalReimbursements(){
      if (!this.contract) {
        return ''
      }
      const rimb = Object.values(this.positionsDataObj)
        .reduce((accumulate, current) => accumulate + Object.values(current)
          .reduce((partialAccumulate, partialCurrent) => partialAccumulate + partialCurrent.reimbursement, 0), 0)

      return Math.round(rimb)
    },
    positionsDataObj() {
      if (!this.contract || !this.contract.positions) {
        return {}
      }
      let data = {}
      for (const position of this.contract.positions) {
        let productObj = data[position.product.name] ? data[position.product.name] : {}
        const items = position.numberOfItems
        const ppi = position.pricePerItem
        let iterationDate = new Date(position.startTerm)
        const endDate = position.endTerm ? new Date(position.endTerm) : new Date()
        while (iterationDate <= endDate) {
          let month = iterationDate.getMonth() + 1
          if(month < 10) {
            month = `0${month}`
          }
          let currentDateKey = iterationDate.getFullYear().toString() + '-' + month.toString();
          if (productObj[currentDateKey]) {
            if (!position.isDiscount && !position.isReimbursement) {
              productObj[currentDateKey].items = productObj[currentDateKey].items + items;
              productObj[currentDateKey].price = productObj[currentDateKey].price + (ppi * items);
            }
            if (position.isDiscount && !position.isReimbursement) {
              productObj[currentDateKey].price = productObj[currentDateKey].price + (ppi * items);
            }
            if (position.isReimbursement) {
              productObj[currentDateKey].reimbursement = productObj[currentDateKey].reimbursement + (ppi * items);
            }
          } else {
            if (!position.isDiscount && !position.isReimbursement) {
              productObj[currentDateKey] = {'items': items, 'price': ppi * items, 'reimbursement': 0};
            }
            if (position.isDiscount && !position.isReimbursement) {
              productObj[currentDateKey] = {'items': 0, 'price': ppi * items, 'reimbursement': 0};
            }
            if (position.isReimbursement) {
              productObj[currentDateKey] = {'items': 0, 'price': 0, 'reimbursement': ppi * items};
            }
          }
          iterationDate.setMonth(iterationDate.getMonth() + 1);
        }
        data[position.product.name] = productObj;
      }
      return data
    },
    tableItems() {
      if (!this.contract) {
        return []
      }
      const data = this.positionsDataObj
      let returnList = []
      for (const prd in data) {
        const returnObj = {}
        let totalItems = 0
        let totalPrice = 0
        for (const monthDate of this.tableFields) {
          if (data[prd][monthDate]) {
            returnObj[monthDate] = data[prd][monthDate].items > 0 ? data[prd][monthDate].items + 'x' + Math.round(data[prd][monthDate].price / data[prd][monthDate].items) + '€' : '';
            totalItems = totalItems + data[prd][monthDate].items;
            totalPrice = totalPrice + data[prd][monthDate].price;
          } else {
            returnObj[monthDate] = ''
          }
        }
        returnObj['Product'] = prd;
        returnObj['AVG'] = totalItems > 0 ? Math.round(totalPrice / totalItems) + '€' : '';
        returnList.push(returnObj);
      }
      return returnList
    },
    tableFields(){
      if (!this.contract) {
        return []
      }
      let positionMonths = Object.keys(Object.values(this.positionsDataObj).reduce((current, finalObj) => {
        return {
          ...finalObj,
          ...current
        }
      }))
      return [
        'Product',
        'AVG',
        ...positionMonths.sort()
      ]
    }

  },
  apollo: {
  },
  watch:{
  }
}

</script>

<style scoped>
  .overview-item {
    text-align: left;
    padding-left: 30px;
    padding-bottom: 10px;
  }
</style>
